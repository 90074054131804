export const spacing = {
  px: "1px",
  0.5: "calc(var(--base-spacing) * 0.083)",
  1: "calc(var(--base-spacing) * 0.167)",
  1.5: "calc(var(--base-spacing) * 1)",
  2: "calc(var(--base-spacing) * 0.333)",
  2.5: "calc(var(--base-spacing) * 0.417)",
  3: "calc(var(--base-spacing) * 0.5)",
  3.5: "calc(var(--base-spacing) * 0.583)",
  4: "calc(var(--base-spacing) * 0.667)",
  5: "calc(var(--base-spacing) * 0.833)",
  6: "calc(var(--base-spacing) * 1)",
  7: "calc(var(--base-spacing) * 1.167)",
  8: "calc(var(--base-spacing) * 1.333)",
  9: "calc(var(--base-spacing) * 1.5)",
  10: "calc(var(--base-spacing) * 1.667)",
  12: "calc(var(--base-spacing) * 2)",
  14: "calc(var(--base-spacing) * 2.333)",
  16: "calc(var(--base-spacing) * 2.667)",
  20: "calc(var(--base-spacing) * 3.333)",
  24: "calc(var(--base-spacing) * 4)",
  28: "calc(var(--base-spacing) * 4.667)",
  32: "calc(var(--base-spacing) * 5.333)",
  36: "calc(var(--base-spacing) * 6)",
  40: "calc(var(--base-spacing) * 6.667)",
  44: "calc(var(--base-spacing) * 7.333)",
  48: "calc(var(--base-spacing) * 8)",
  52: "calc(var(--base-spacing) * 8.667)",
  56: "calc(var(--base-spacing) * 9.333)",
  60: "calc(var(--base-spacing) * 10)",
  64: "calc(var(--base-spacing) * 10.667)",
  72: "calc(var(--base-spacing) * 12)",
  80: "calc(var(--base-spacing) * 13.333)",
  96: "calc(var(--base-spacing) * 16)",
};
